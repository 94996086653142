'use client'
import { type ComponentPropsWithoutRef, type MouseEvent, useState } from 'react'
import Modal from '@components/modal'
import LocationHours from '@app/(main)/locations/_components/location-hours'
import type { TypeScheduleAmended, TypeScheduleException } from '@lib/location-types'
import { Link, Typography } from '@shc/ui'

export interface LocationDetailProps extends ComponentPropsWithoutRef<'div'> {
  now: string
  name: string
  description?: string | null
  address?: string | null
  phone?: string | null
  fax?: string | null
  mapUrl?: string
  displayHours?: boolean
  schedule?: TypeScheduleAmended[]
  scheduleNotes?: string | null
  scheduleExceptions?: TypeScheduleException[]
  link?: string | null
}

const LocationDetail = ({
  now,
  name,
  description,
  address,
  phone,
  fax,
  mapUrl,
  displayHours = false,
  schedule,
  scheduleNotes,
  scheduleExceptions,
  link,
  className,
  ...props
}: LocationDetailProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div className={className} {...props}>
      <Typography variant="h2" className="pb-6">
        How to find us
      </Typography>
      <div className="flex flex-row gap-5 items-center">
        <div className="flex flex-col gap-3 md:basis-1/2">
          {!link && <div className="text-base font-semibold">{name}</div>}
          {link && (
            <Link noUnderline href={link}>
              {name}
            </Link>
          )}
          {description && <div>{description}</div>}
          {address && (
            <div className="text-base font-medium">
              <div className="whitespace-pre-line">{address}</div>
              <div>
                <Link
                  href={`https://google.com/maps/search/?api=1&query=${name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Get directions"
                  noUnderline>
                  Get directions
                </Link>
              </div>
            </div>
          )}
          {phone && (
            <div>
              <Link noUnderline href={`tel:+1${phone}`}>
                {phone}
              </Link>
              {fax && <div>Fax: {fax}</div>}
            </div>
          )}
          {displayHours && (
            <div>
              <LocationHours
                now={now}
                schedule={schedule as TypeScheduleAmended[]}
                scheduleNotes={scheduleNotes}
                scheduleExceptions={
                  Array.isArray(scheduleExceptions) ? scheduleExceptions : undefined
                }
                summaryOnly={true}
              />
              <Link
                href=""
                noUnderline
                onClick={(e: MouseEvent<any>) => {
                  setIsModalOpen(true)
                  e.preventDefault()
                }}>
                View hours
              </Link>
            </div>
          )}
        </div>
        {mapUrl && mapUrl.length > 0 && (
          <div className="hidden md:block md:basis-1/2">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <Link
              href={`https://google.com/maps/search/?api=1&query=${name}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Map Get Directions">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={mapUrl}
                alt="Google Map"
                className="rounded w-full object-contain object-top"
              />
            </Link>
          </div>
        )}
      </div>
      {displayHours && (
        <Modal
          size="sm"
          contentName="DepartmentHoursModal"
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}>
          <Modal.Description>
            <LocationHours
              now={now}
              schedule={schedule as TypeScheduleAmended[]}
              scheduleNotes={scheduleNotes}
              scheduleExceptions={
                Array.isArray(scheduleExceptions) ? scheduleExceptions : undefined
              }
            />
          </Modal.Description>
        </Modal>
      )}
    </div>
  )
}

export default LocationDetail
