'use client'

import { cloneElement, isValidElement, type ReactElement, type ReactNode } from 'react'

type ChatWrapperProps = {
  chat?: boolean
  children: ReactNode
}

export default function ChatWrapper({ chat = false, children }: ChatWrapperProps) {
  if (chat && isValidElement(children)) {
    return cloneElement(children as ReactElement, {
      onClick: () => window._uwc('toggle'),
    })
  } else {
    return children
  }
}
