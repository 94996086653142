'use client'

import { useCountUp } from 'react-countup'
import { useRef, type ComponentPropsWithoutRef } from 'react'
import { getIcon } from './icons'
import { Icon, Typography, Card } from '@shc/ui'

export interface CardStatProps extends ComponentPropsWithoutRef<'div'> {
  icon: string
  prefix?: string
  startingValue?: number
  finalValue: number
  suffix?: string
  summary?: string
}

/**
 * @todo - update when SharpUI Card component has been changed
 */
const CardStat = ({
  icon,
  prefix = '',
  finalValue,
  startingValue = finalValue,
  suffix = '',
  summary,
  className,
  ...props
}: CardStatProps) => {
  const countUpRef = useRef(null)
  const isInt = (n: number) => n % 1 === 0
  const hasDecimals = (number: number) => {
    return isInt(number) ? 0 : Number(number.toString().split('.')[1].length)
  }
  useCountUp({
    ref: countUpRef,
    start: startingValue,
    end: finalValue,
    prefix: prefix,
    suffix,
    separator: ',',
    enableScrollSpy: true,
    decimals: hasDecimals(finalValue),
  })
  return (
    <div className={className} {...props}>
      <Card className="flex flex-col items-center p-10 h-full space-y-0">
        {icon && <Icon icon={getIcon(icon)} className="text-primary h-8" />}
        <p className="text-primary text-5xl font-bold h-16 !mt-2" ref={countUpRef} />
        {summary && (
          <Typography variant="body-semibold" align="center">
            {summary}
          </Typography>
        )}
      </Card>
    </div>
  )
}

export default CardStat
