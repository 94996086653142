'use client'
import { ptMap } from '@lib/token-maps'
import clsx from 'clsx'
import { type ComponentPropsWithoutRef } from 'react'
import usePagination from 'headless-pagination-react'
import { Button, Icon } from '@shc/ui'

export interface PaginationProps extends ComponentPropsWithoutRef<'div'> {
  totalItems: number
  pageClickLink?: string
  router?: any
  pageClickFunction?: (newPage: number) => void
  pageIndexStart?: 0 | 1
  itemsPerPage?: number
  maxLinks?: number
  currentPageNumber?: number
  alignment?: 'left' | 'center' | 'right'
}

const Pagination = ({
  totalItems,
  pageClickFunction,
  pageIndexStart = 1,
  itemsPerPage = 8,
  maxLinks = 7,
  currentPageNumber = 1,
  alignment = 'center',
  className = ptMap['60px'],
  ...props
}: PaginationProps) => {
  const { links, setPage, hasNext, hasPrevious } = usePagination({
    totalItems: totalItems,
    perPage: itemsPerPage,
    maxLinks: maxLinks,
    initialPage: currentPageNumber,
  })

  const alignmentMap = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  }

  function onPageClick(page: number) {
    setPage(page)

    if (typeof pageClickFunction !== 'undefined') {
      const realPage = pageIndexStart === 0 ? page - 1 : page
      pageClickFunction(realPage)
    }
  }

  return (
    <div
      className={clsx(
        'col-span-12 flex flex-row flex-wrap sm:gap-5',
        alignmentMap[alignment],
        className
      )}
      {...props}>
      <Button
        size="xs"
        shape="circle"
        width="auto"
        color="transparent"
        disabled={!hasPrevious}
        onClick={() => {
          onPageClick(currentPageNumber - 1)
        }}
        aria-label="Previous page">
        <Icon icon="chevron-left" className="text-primary-700 w-[14px] h-[14px]" />
      </Button>
      {links.map((link, index) => (
        <Button
          key={index}
          color={currentPageNumber === link.label ? 'primary' : 'transparent'}
          size="xs"
          shape="circle"
          width="auto"
          className="!opacity-100"
          disabled={link.disabled}
          onClick={() => {
            const pageNum = parseInt(link.label as string, 10)
            onPageClick(pageNum)
          }}
          aria-label={`Page ${link.label}`}>
          <p className="text-sm font-medium">{link.label}</p>
        </Button>
      ))}
      <Button
        size="xs"
        shape="circle"
        width="auto"
        color="transparent"
        disabled={!hasNext}
        onClick={() => {
          onPageClick(currentPageNumber + 1)
        }}
        aria-label="Next page">
        <Icon icon="chevron-right" className="text-primary-700 w-[14px] h-[14px]" />
      </Button>
    </div>
  )
}

export default Pagination
