'use client'
import clsx from 'clsx'
import { ptMap, pbMap, cardWidthMap, columnsMaxCardMap, colWidthCenterMap } from '@lib/token-maps'
import Analytics from '@components/analytics'
import Card from '@components/card'
import type { TypeHealthNewsStoriesBlockFields } from '@lib/generated-types'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import CaptionedMedia from '@cms/components/captioned-media'
import { blockQueryParamMap, contentTypeBasePathMap } from '@lib/constants'
import type { NewsArticles } from '@lib/types'
import Pagination from '@components/pagination'
import slugify from '@sindresorhus/slugify'
import { useRouter } from 'next/navigation'
import { doScroll } from '@hooks/useCustomScroll'
import { Container } from '@shc/ui'

export interface HealthNewsStoriesBlockProps extends TypeHealthNewsStoriesBlockFields {
  relatedNews: NewsArticles
  currentPageNumber: number
  currentPage: string
}

const HealthNewsStoriesBlock = ({
  internalName,
  sectionLink,
  topics,
  authors,
  articlesToExclude,
  cardsMax,
  columnsMax,
  width,
  isPaginated,
  paddingTop,
  paddingBottom,
  relatedNews,
  currentPageNumber,
  currentPage,
  ...props
}: HealthNewsStoriesBlockProps) => {
  const router = useRouter()

  const onPageChange = (page: number) => {
    router.push(currentPage + '?' + blockQueryParamMap['healthNewsStoriesBlock'] + '=' + page, {
      scroll: false,
    })
    const element = document.getElementById(slugify(sectionLink ?? ''))
    if (element !== null) {
      doScroll(element.getBoundingClientRect().top + window.scrollY - window.scrollOffSet)
    }
  }

  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink as string)}
      className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
      data-block="healthNewsStoriesBlock"
      {...props}>
      <div
        className={clsx(
          colWidthCenterMap[width],
          'col-span-12 col-start-1 flex flex-row flex-wrap gap-x-5 gap-y-8 lg:gap-y-12'
        )}>
        {relatedNews &&
          relatedNews.data.length > 0 &&
          relatedNews.data.map(({ fields }, index) => {
            return (
              <Analytics
                key={index}
                click={{
                  name: 'component_click',
                  data: {},
                }}
                contexts={[
                  {
                    name: 'component',
                    data: {
                      component_text: fields.title,
                      component_url: contentTypeBasePathMap['newsArticle'] + fields.slug,
                    },
                  },
                ]}>
                <Card
                  title={fields.title}
                  summary={fields.summary}
                  image={
                    <CaptionedMedia
                      {...fields.image.fields}
                      aspectRatio="16:9"
                      className="rounded overflow-hidden"
                      imageClassName={clsx(
                        'rounded',
                        'scale-100',
                        'duration-200',
                        'group-hover:scale-105',
                        'group-hover:origin-center',
                        'group-hover:duration-200'
                      )}
                      extras={generateBaseMediaQWidths(4)}
                      showCaption={false}
                    />
                  }
                  link={contentTypeBasePathMap['newsArticle'] + fields.slug}
                  className={clsx('group', cardWidthMap[columnsMaxCardMap[columnsMax]])}
                />
              </Analytics>
            )
          })}
      </div>
      {isPaginated && relatedNews.total > cardsMax && (
        <Pagination
          totalItems={relatedNews.total}
          itemsPerPage={cardsMax}
          currentPageNumber={currentPageNumber}
          pageClickFunction={onPageChange}
        />
      )}
    </Container>
  )
}

export default HealthNewsStoriesBlock
